<script lang="ts">
import {computed, defineComponent, inject, onMounted} from 'vue'
import Header from "@/components/header/Header.vue"
import {defaultCurrency} from "@/utils/currencyUtil";
import useUserStore from "@/store/user";
import HeaderDto from "@/service/model/header/HeaderDto";
import Footer from "@/components/footer/Footer.vue";
import Error from "@/views/Error.vue"
import NotFound from "@/views/NotFound.vue";
import { useRoute } from 'vue-router';
export default defineComponent({
  components: {
    NotFound,
    Footer,
    Header,
    Error
  },
  methods: {
    onRouteClick(url: string) {
      this.$router.push(url)
    },
  },
  setup() {
    const userStore = useUserStore();
    let headInfo = <HeaderDto>inject('headInfo');
    defaultCurrency['key'] = headInfo.currencyDto;
    userStore.updateHeader(headInfo);
    const route = useRoute();
    const disableGlobalContStyle = computed(() => route.meta.disableGlobalContStyle);
    return {
      userStore,
      disableGlobalContStyle
    }
  }
})
</script>
<template>
  <div class="docWrap" >
    <Header></Header>
    <div :class="{'main-app content': !disableGlobalContStyle}">
      <NotFound v-if="userStore.pageNotFound"></NotFound>
      <Error v-else-if="userStore.systemError"></Error>
      <router-view v-else></router-view>
    </div>
    <client-only>
      <Footer></Footer>
    </client-only>
  </div>
</template>
<style src="@/assets/styles/style.less" lang="less"></style>
