import {defineComponent, toRefs} from "vue";

export default defineComponent({
    props: {
        firstCrumb: {
            required: true,
            type: String,
            default: ''
        },
        secondCrumb: {
            type: String,
            default: null
        },
        firstUrl: {
            type: String,
            default: ''
        },
        secondUrl: {
            type: String,
            default: ''
        }
    },
    setup(){}
})
