import common from "@/components/common";

const NO_PHOTO_FILENAME = "nophoto.jpg";

export function getPhotoUrl(photoName: string, size = 0): string {
    if (photoName === '') {
        photoName = NO_PHOTO_FILENAME;
    }
    let photoRules = [{value:"https://img3.cobeads.com/photos/",key:/^[a-z]/},{value:"https://img4.cobeads.com/photos/",key:/^[0-3]/},{value:"https://img5.cobeads.com/photos/",key:/^[4-9]/}];
    let rule = photoRules.filter(a => a.key.test(photoName)).shift();
    if (!rule) {
        rule = photoRules[0];
    }
    return `${rule.value}${getResizePhotoName(photoName, size)}`;
}

function getResizePhotoName(photoName: string, size: number): string {
    let folder = "";
    if (photoName.length > 1) {
        folder = photoName.substring(0, 1) + "/" + photoName.substring(1, 2) + "/"
    }
    if (size == 0) {
        return `${folder}${photoName}`;
    }
    return `${folder}${size}_${size}_${photoName}`;
}

export function defDirective(app, store) {

    app.directive("buyNow", {
        deep:true,
        mounted: (el, binding) => {
            if(!binding.value){
                console.error("buyNow指令没有设置商品id")
            }

            el.onclick = function () {
                common.buyNow(binding.value.id, binding.value.qty, el, store);
            }
        },
        updated:(el, binding) =>{
            el.onclick = function () {
                common.buyNow(binding.value.id, binding.value.qty, el,store);
            }
        }
    });

    app.directive("addToCart", {
        deep:true,
        mounted: (el, binding) => {
            if (!binding.value) {
                console.error("addToCart指令没有设置商品id")
            }
            el.onclick = function () {
                common.addToCart(binding.value.id, binding.value.qty, el,store);
            }
        },
        updated:(el, binding) =>{
            el.onclick = function () {
                common.addToCart(binding.value.id, binding.value.qty, el,store);
            }
        }
    });

    app.directive("num", {
        mounted: (el, binding) => {
            let wins_0 = /[^\d]/g //整数判断
            let wins_1 = /[^\d^\.]/g //小数判断
            let flag = true;
            let points = false;
            let lengths = false;
            let remainder = false;
            let no_int = false;
            const target = el instanceof HTMLInputElement ? el : el.querySelector("input");
            target.addEventListener("compositionstart", e => {
                flag = false;
            });
            target.addEventListener("compositionend", e => {
                flag = true;
            });
            //在监听input的回调中触发input会导致无限循环所以使用keyup
            target.addEventListener("keyup", e => {
                if (flag) {
                    if (binding.value == 0) {
                        if (wins_0.test(e.target.value)) {
                            e.target.value = e.target.value.replace(wins_0, "");
                            //触发input,手动更新v-model值
                            e.target.dispatchEvent(new Event("input"))
                        }
                    }
                    if (binding.value >0) {
                        if (wins_0.test(e.target.value.toString().replace(/\d+\.(\d*)/, '$1'))) {
                            remainder = true;
                        }
                        if ((e.target.value.split('.')).length - 1 > 1) {
                            points = true;
                        }
                        if (e.target.value.toString().split(".")[1] != undefined) {
                            if (e.target.value.toString().split(".")[1].length > 2) {
                                lengths = true;
                            }
                        }
                        if (e.target.value.toString().indexOf(".") != -1) {
                            no_int = false;
                        } else {
                            no_int = true;
                        }
                        if (wins_1.test(e.target.value) || lengths || points || remainder) {
                            if (!no_int) {
                                e.target.value = e.target.value.replace(wins_1, "").replace('.', '$#$').replace(/\./g, '').replace(
                                    '$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').substring(0, e.target.value.indexOf(
                                    ".") + binding.value+1)
                            } else {
                                e.target.value = e.target.value.replace(wins_0, "")
                            }
                            e.target.dispatchEvent(new Event("input"))
                        }
                    }
                    if( /^0\d.*/g.test(e.target.value)){
                        e.target.value = e.target.value.substring(1,e.target.value.length);
                        e.target.dispatchEvent(new Event("input"));
                    }
                }
            })
        }
    });

    app.directive("addToWishList", {
        mounted: (el, binding) => {
            if(!binding.value){
                console.error("addToWishList指令没有设置商品id")
            }

            el.onclick = function () {
                common.addToWishList(binding.value,el,null,store);
            }
        }
    });

    app.directive("productImg", {
        mounted: (el, binding) => {
            el.src = getPhotoUrl(binding.value.photoName, binding.value.size);
        }
    });

    // 商品图片 延迟加载
    app.directive('lazy-load-img', {
        deep:true,
        mounted(el, binding) {
            const { value: { photoName, size, defaultImageUrl } } = binding;
            el.classList.add('lazy');
            el.src = defaultImageUrl;
            const loadImage = () => {
                const img = new Image();
                img.src = getPhotoUrl(photoName, size);
                img.onload = () => {
                    el.src = getPhotoUrl(photoName, size);
                    el.classList.remove('lazy'); // 移除 `lazy` 类
                    el.classList.add('loaded'); // 可选，添加加载完成的样式
                };
                img.onerror = () => {
                    el.src = defaultImageUrl; // 如果加载失败，设置为默认图片
                    el.classList.remove('lazy'); // 即使加载失败，也移除 `lazy` 类
                };
            };


            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        loadImage();
                        observer.unobserve(el);
                    }
                });
            });
            observer.observe(el); // 监听当前指令所绑定的元素
        },
        updated(el, binding) {
            const { value: { photoName, size, defaultImageUrl } } = binding;
            const img = new Image();
            img.src = getPhotoUrl(photoName, size);
            img.onload = () => {
                el.src = getPhotoUrl(photoName, size);
                el.classList.remove('lazy');
                el.classList.add('loaded');
            };
            img.onerror = () => {
                el.src = defaultImageUrl;
                el.classList.remove('lazy');
            };
        }
    });

    //  所有图片 延迟加载
    app.directive('lazy-img', {
        mounted(el, binding) {
            const defaultImage = '/imgs/load_1_1.gif'; // 替换为默认图片的路径
            el.classList.add('lazy');
            el.src = defaultImage;
            const loadImage = (src) => {
                const img = new Image();
                img.src = src;
                img.onload = () => {
                    el.src = src;
                    el.classList.remove('lazy'); // 移除 `lazy` 类
                    el.classList.add('loaded'); // 可选，添加加载完成的样式
                };
                img.onerror = () => {
                    el.src = defaultImage; // 如果加载失败，设置为默认图片
                    el.classList.remove('lazy'); // 即使加载失败，也移除 `lazy` 类
                };
            };

            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        loadImage(binding.value);
                        observer.unobserve(el);
                    }
                });
            });

            observer.observe(el);
        }
    })
}