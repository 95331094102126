import {reactive} from "vue";
import CountryZipRule from "@/service/model/CountryZipRule";

var rules={
    validForm:{},
    verifyASCII : function(value){
        var ASCIIReg = /[^\x00-\xff]+/g;
        if(value != "" && ASCIIReg.test(value)){
            if(this.validForm["countryId"] != "53"){
                return "myaccount.addressBook.enterEnglish".translate();
            }
        }
        return true;
    },
    loginPassword: (value):any => {
        if (!value || !value.length) {
            return 'login.password.empty'.translate();
        }
        return true;
    },
    email: value => {
        // Field is empty, should pass
        if (!value || !value.length) {
            return 'login.email.empty'.translate();
        }
        // Check if email
        if (!/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
            return 'login.email.notexists'.translate();
        }
        return true;
    },
    validateCode: value => {
        if (!value || !value.length) {
            return 'login.validatecode.empty'.translate();
        }
        return true;
    },
    password: (value) => {
        // The field is empty so it should pass
        let re = rules.loginPassword(value);
        if (re!=true) {
            return re;
        }
        if (value.length < 6) {
            return 'register.password.type'.translate()
        }
        return true;
    },

    repeatPassword: function (value)  {
        if (!value || !value.length) {
            return 'login.password.empty'.translate();
        }
        if (value ==this.validForm["password"]) {
            return true;
        }
        return 'register.resetpassword'.translate();
    },

    firstName: value => {
        if (!value || !value.length) {
            return 'register.firstname.empty'.translate();
        }
        return true;
    },

    lastName: value => {
        if (!value || !value.length) {
            return 'register.lastname.empty'.translate();
        }
        return true;
    },

    oldPwd: value => {
        if (!value || !value.length) {
            return 'register.oldpassword.empty'.translate();
        }
        return true;
    },
    companyName:function (value){
        if (value && value.length>35) {
            return 'myaccount.addressBook.companyNameMsg'.translate();
        }
        if(this.validForm["countryId"]==53){
            return true;
        }
        return this.verifyASCII(value);
    },
    addressFirstName:function (value){
        if (!value || !value.length) {
            return 'register.firstname.empty'.translate();
        }
        if( (this.validForm['firstName']+this.validForm['lastName']).length>34){
            return "myaccount.addressBook.fullNameMsg".translate();
        }
        return this.verifyASCII(value);
    },
    addressLastName:function (value){
        if (!value || !value.length) {
            return 'register.lastname.empty'.translate();
        }
        if( (this.validForm['firstName']+this.validForm['lastName']).length>34){
            return "myaccount.addressBook.fullNameMsg".translate();
        }
        return this.verifyASCII(value);
    },
    phone:function(value){
        if (!value || !value.length || value.length>30) {
            return "myaccount.addressBook.phoneMsg".translate();
        }
        return true;
    },
    street1:function(value){
        if (!value || !value.length || value.length>100) {
            return "myaccount.addressBook.streetMsg".translate();
        }
        return this.verifyASCII(value);
    },
    street2:function(value){
        if (!value || !value.length){
            return true;
        }
        if ( value.length>100) {
            return "myaccount.addressBook.streetMsg".translate();
        }
        return this.verifyASCII(value);
    },
    countryId:function(value){
        if (value==null || value==0 ) {
            return "myaccount.addressBook.countryMsg".translate();
        }
        return true;
    },
    city31:function (value){
        var cityReg = /^((Las Palmas)|(las palmas de gran canaria)|(puerto de la cruz)|(TEGUISE)|(san bartolome de tirajana)|(Lanzarote)|(SANTA CRUZ DE TENERIFE TENERIFE)|(Vega de San Mateo)|(Los Cristianos)|(Cuesta de Arguijon,La))$/i;
        if(this.validForm['countryId'] == "31" &&  cityReg.test(value) ){
            this.validForm['countryId']=261;
        }
    },
    zip31:function (value){
        var zipReg = /^((35)|(38))/g;
        if(this.validForm['countryId'] == "31" &&  zipReg.test(value) ){
            this.validForm['countryId']=261;
        }
    },
    city:function(value){
        if (!value || !value.length || value.length>30) {
            return "myaccount.addressBook.cityMsg".translate();
        }
        let NumReg = /\d+/g;
        if(NumReg.test(value)){
            return "myaccount.addressBook.cityFormatMsg".translate();
        }
        if(this.validForm['countryId'] == "103") {
            if(value.toLowerCase()=='pr' || value.toLowerCase()=='pur' || value.toLowerCase().indexOf("puerto rico")!=-1 || value.toLowerCase().indexOf("the commonwealth of puerto rico")!=-1){
                this.validForm['countryId']=83;
            }
        }
        if(this.validForm['countryId'] == "34" || this.validForm['countryId'] == "201") {
            if(value.toLowerCase().indexOf("reunion")!=-1){
                this.validForm['countryId']=250;
            }
        }
        this.city31(value);
        return this.verifyASCII(value);
    },
    state:function(value){
        if (!value || !value.length || value.length>30) {
            return "myaccount.addressBook.stateMsg".translate();
        }
        if (value.toLowerCase() == "puerto rico" || value.toLowerCase() == "p.r." || value.toLowerCase() == "pr") {
            this.validForm['countryId']=83;
        }
        if (value.toLowerCase().indexOf("reunion")!=-1) {
            this.validForm['countryId']=250;
        }
        this.city31(value);
        return this.verifyASCII(value);
    },
    zip:function(value){

        let r = /\s+/g;
        let s = /-+/g;
        value = value.replace(r," ").replace(s,"-");
        this.validForm['zip']=value;

        let zipRule:CountryZipRule|undefined=this.validForm["countryZipRule"].filter(r=>r.countryId == this.validForm['countryId']).shift();
        // 判断29个常用国家邮编
        if(zipRule!=null){
            var regex:string=zipRule.regex!.replaceAll("\\\\","\\");
            var newR = new RegExp(regex.trim());
            if(newR.test(value) == false){
                return zipRule.message;
            }
        }else{
            if (!value || !value.length || value.length>10) {
                return "myaccount.addressBook.zipMsg".translate();
            }
            var regZip = /^[a-zA-Z0-9_\s\-]+$/g;
            if(!regZip.test(value)){
                return  "myaccount.addressBook.zipFormatMsg".translate();
            }
        }
        this.zip31(value);
        return true;
    },
    fax:function(value){
        if(this.validForm["acceptMessage"] && (!value || !value.length)){
            return "myaccount.addressBook.faxMsg".translate();
        }
        if (value.length>30) {
            return "myaccount.addressBook.faxMsg".translate();
        }
        return true;
    }
}
export default function useValid(form){
    var validRules={...rules};
    validRules.validForm=form;
    var errors=reactive({
        firstErrorMsg:"",
        clear:function () {
            this.firstErrorMsg = ""
            for (const [key, val] of Object.entries(this)) {
                if(key !="clear"){
                    this[key]="";
                }
            }
        }
    });

    var validField=(filedName)=>{
        if(validRules[filedName]){
            if(form[filedName]!=undefined && typeof form[filedName] =="string"){
                form[filedName]=form[filedName].trim();
            }

            var re = validRules[filedName](form[filedName]);
            if(re!=true){
                errors[filedName]=re;
            }else{
                errors[filedName]=undefined;
            }
        }
        return errors[filedName]==undefined;
    };
    var valid=(exclude=[])=>{
        errors.firstErrorMsg="";
        for (const [key, val] of Object.entries(form)) {
            if(form[key]!=undefined&&!validField(key)){
                if(exclude.indexOf(errors[key])<0){
                    errors.firstErrorMsg=errors[key];
                }

            }
        }
        return errors.firstErrorMsg=="";
    }
    return {errors, validField,valid,validRules};
}
