import {reactive, toRefs, onMounted, defineComponent, PropType, computed, inject} from 'vue'
import {PageLocationType, headerController, LocationType} from '@/service/HeaderController';

import HeaderDto from "@/service/model/header/HeaderDto";
import HeaderCustomerDto from "@/service/model/header/HeaderCustomerDto";
import {useRoute, useRouter} from "vue-router";
import {getScreen, PhScreen} from "@/utils/media";

export default defineComponent({
    props:{
        pageLocation: Object as PropType<PageLocationType>,
        locationType: Object as PropType<LocationType>,
        isFix: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const state = reactive({
            isShowAllCate: <boolean> false,
            categoryTree: <any> [],
            headInfo: <HeaderDto> {},
            headCustomerInfo: <HeaderCustomerDto>{},
            isSignIn: <boolean> false,
            cateImg: <any> [],
            holiday: <any> null,
            current: <any> '',
            isShowNav: <boolean> false,
            showSecondCateIndex: -1, // 用来记录当前显示的二级类别的索引
            isShowHoliday: false,
            shouldShowElement: true
        })
        let route = useRoute();
        state.headInfo = <HeaderDto> inject('headInfo');
        state.isSignIn = state.headInfo.customerId != null;
        onMounted(async ()=>{

            // 获取类别树
            state.categoryTree = await headerController.listHeaderCategoryTree();

            // 首页展示 所有类别
            if(route.name == 'index'){
                state.isShowAllCate = true;
            }
            let preview=0;
            if(window.location.href.indexOf("preview=1") > 0){
                preview=1;
            }
            state.cateImg = await headerController.loadCategory1Banner(preview);
            state.holiday = await headerController.holidayAndCollections(preview);
            window.addEventListener("scroll", showAllCate,true);

            // 手机端不显示pc 节点
            state.shouldShowElement = ! (getScreen() == PhScreen.PHONE || getScreen()==PhScreen.PHONE_SMALL)

        })

        // 首页头部固定, 下拉收起展开
        const showAllCate=()=>{
            if(props.isFix) {
                state.isShowAllCate = false;
            } else {
                if(route.name == 'index'){
                    state.isShowAllCate = true;
                } else {
                    state.isShowAllCate = false;
                }
            }
        }

        // 显示隐藏所有类别
        const showAllCategories = (index)=> {
            state.isShowAllCate = true;
            state.current = index;
        }
        // 隐藏所有类别
        const hideAllCategories=()=> {
            if(route.name != 'index'){
                state.isShowAllCate = false;
            } else {
                state.current = 0;
            }
        }

        // 显示二级类别
        const showSecondCate = (index: number) => {
            state.showSecondCateIndex = index;
        };

        const tempCate = computed(() => {
            let list: [] = state.categoryTree, index: number = 0, resultMap = new Map(), arrTemp:any =[];
            list.forEach((item, idx)=>{
                let subCate = item['subCategories'],id = item['category']['id'];
                Object.keys(subCate).forEach(function (key){
                    // @ts-ignore
                    index = parseInt(String(key / 12));
                    if(arrTemp[index]==null){
                        arrTemp[index] = [];
                    }
                    if(subCate[key]['category']['parentId'] == id){
                        arrTemp[index].push(subCate[key]);
                    }
                })
                resultMap.set(id,JSON.parse(JSON.stringify(arrTemp)));
                arrTemp=[];
            })
            return resultMap;
        })

        const showHoliday = (showHolidayFlag:boolean) =>{
            state.isShowHoliday = showHolidayFlag
        }

        return {
            ...toRefs(state),
            showAllCategories,
            hideAllCategories,
            showSecondCate,
            tempCate,
            showAllCate,
            showHoliday
        }
    }
})
