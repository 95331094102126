import {
  reactive,
  toRefs,
  onMounted,
  defineComponent,
  PropType,
  inject,
  nextTick,
  onBeforeMount,
  ref,
  onServerPrefetch
} from 'vue';
import {PageLocationType, headerController} from '@/service/HeaderController';
import CurrencyDto from "@/service/model/header/CurrencyDto";
import PromotionBannerDto from "@/service/model/header/PromotionBannerDto";
import {getScreen, PhScreen} from "@/utils/media";
import HeaderDto from "@/service/model/header/HeaderDto";
import NotificationDto from "@/service/model/header/NotificationDto";
import Cookies from 'js-cookie'
import useUserStore from "@/store/user";
import HeaderCustomerDto from "@/service/model/header/HeaderCustomerDto";
import {useContext} from "@/Ph";
import {getCookie} from "@/utils/cookie"
import Promotion from "@/components/header/pcHeader/promoBanner/Promotion.vue";

export default defineComponent({
  components: {Promotion},
  props: {
    pageLocation: Number
  },
  setup(props) {

    const context = useContext()
    const state = reactive({
      curList: <CurrencyDto[]>[],
      currencyDisplay: <boolean>false,
      defCurrency: <CurrencyDto>{},
      promotionBannerDto: <PromotionBannerDto>{},
      headInfo: <HeaderDto>{},
      headCustomerInfo: <HeaderCustomerDto>{},
      isSignIn: <boolean>false,
      notificationList: <NotificationDto[]>[],
      isShowNotice: false,
      headerShowStockProduct:false
    })

  state.headInfo = inject('headInfo')

  state.isSignIn = state.headInfo.customerId != null;

  state.defCurrency = state.headInfo.currencyDto!;
  // 通知
  state.notificationList = state.headInfo.headNotificationList;
      // 库存开关按钮判
  let pStock =  getCookie("pStock")

  if(pStock == null){
      state.headerShowStockProduct = false;
  }else{
      state.headerShowStockProduct = pStock == "1"? true: false;
  }
  state.isShowNotice = getCookie("isShowNotice") == null;

    onMounted(async () => {

      // 币种下拉列表
      let curList = <CurrencyDto[]>await headerController.getAllCurrencies();
      state.curList = curList;
    })

    // 鼠标移入
    const currencyIsDisplay = (flag) => {
      state.currencyDisplay = flag
    }

    // 选择币种
    const changeCurrency = async (code) => {
      await headerController.changeCurrency(code);
      let url = window.location.href;
      if (url.indexOf("?currency=") != -1) {
        url = url.substring(0, url.indexOf("?"));
        window.location.href = url;
      } else if (url.indexOf("&currency=") != -1) {
        url = url.replace(/&currency=[A-Z]{3}/g, "");
        window.location.href = url;
      } else {
        window.location.reload();
      }
    }
    // 获取地址栏参数
    const getUrlParams = (name) => {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let param = window.location.search.substr(1).match(reg);
      return param && param[2];
    }
    // 关闭通知
    const handleCloseNotice = () => {
      state.isShowNotice = false;
      Cookies.set('isShowNotice', false);
    }

    // 库存按钮切换
    const showStockProduct = async (showStockProduct) => {
      await headerController.showStockProduct(showStockProduct);
      window.location.reload();
    }
    return {
      ...toRefs(state),
      context,
      currencyIsDisplay,
      changeCurrency,
      getUrlParams,
      handleCloseNotice,
      showStockProduct
    }
  }
})