import {nextTick, onMounted, reactive, ref, toRefs} from 'vue';
import WishListDirDto from "@/service/model/myaccount/WishListDirDto";
import {wishListController} from "@/service/WishListController";
import useUserStore from "@/store/user";
import AddToWishListDto from "@/service/model/myaccount/AddToWishListDto";
import popLogin from "@/components/common/popLogin";

export default {
    name: "AddToWishList",
    props: {
        pids: Array,
        excludeDirId:Number,
        dom: String,
        div: String,
        submitCallback: {
            type: Function // 确认事件
        },
        cancelCallback: {
            type: Function // 取消事件
        }
    },
    setup(props, content) {
        const fade = ref(false)
        const store = useUserStore()
        const data = reactive({
            // wishlist dir 结果集
            wishList: <WishListDirDto[]> [],
            // 默认dir  {name, id}
            defalutListObj: <any> {},
            // input
            createListDirName: <string> '',
            // 创建wistlist dir 失败提示
            createErrorMsg: <string> '',
            // 是否展示dir
            isShowWishListDir: <boolean> false
        })

        onMounted(async () => {
            try {
                // 查询wishlist
                data.wishList = await wishListController.wishListDirList(props.excludeDirId)

                // 默认list
                data.defalutListObj = data.wishList.filter(a => a.updateLatest)[0]

                setPosition(props.dom, props.div)

                // 当元素渲染完毕立即过渡的动画不会触发
                setTimeout(() => {
                    fade.value = true
                }, 0)
            } catch (e){
                popLogin().then(addToWishList, ()=>{}).catch(props.cancelCallback)
            }


        })

        // 添加一个wishlist目录
        const createListDir = async () => {
            try {
                await wishListController.createWishListDir(data.createListDirName,'')

                // 查询wishlist
                data.wishList = await wishListController.wishListDirList(props.excludeDirId)

            }catch (e) {
                let errData = e.response.data;
                if(errData.err_code=="510") {
                    if (errData.message == "WISHLIST_NAME_REPEAT") {
                        data.createErrorMsg = 'Sorry! A list with this name already exists.'
                    }
                }
            }

        }

        // 切换选择dir
        const chooseDir = (item) => {
            data.defalutListObj = item
            // 下拉列表收起
            data.isShowWishListDir = false
        }

        // 加入wish list
        const addToWishList = async () => {
            const returnObj:AddToWishListDto = await wishListController.addToWishList(props.pids,data.defalutListObj.id);

            if(returnObj.needLogin) {
                popLogin().then(addToWishList, ()=>{}).catch(props.cancelCallback)
            } else {
                // 修改cart头部信息
                store.updateHeadWishList(returnObj.count);
                if(window.location.href.indexOf('/p-') > 0) {
                    // 详细页才需要改成 View My Wish List
                    props.dom.innerHTML = "View My Wish List";
                } else {
                    // 列表才需要设置class, 详细页不需要
                    props.dom.setAttribute('class', 'Collect addToWishListBtn ViewMyWishList')
                }
                props.dom.href = `/WishList/${data.defalutListObj.id}.html`;
                props.submitCallback();
            }
        }

        // add to wishList 弹窗位置
        const setPosition = async (clickWrap, popWrap) => {
            await nextTick()
            let {width, height, top, left} = clickWrap.getBoundingClientRect()
            popWrap.style.position = "absolute"
            popWrap.style.zIndex = 99
            popWrap.style.top = top  + height + window.scrollY + 6 + "px"
            popWrap.style.left = left + window.scrollX - ((popWrap.clientWidth- clickWrap.clientWidth)/2) + "px"
        }


        return {
            fade,
            createListDir,
            chooseDir,
            addToWishList,
            ...toRefs(data)
        }
    }
}