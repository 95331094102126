import {reactive, toRefs, defineComponent, ref, onMounted} from 'vue'
import {headerController} from "@/service/HeaderController";

export default defineComponent({
    props:{
        isShowPhoneSearch: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        // 获取file 的值
        const uploadImg = ref(null)
        const state = reactive({
            // 搜索方式, 错误信息, 输入图片路径
            keyWorkList: <any> [],
            searchVal: <string> ''
        })

        onMounted(async ()=>{
            state.keyWorkList = await headerController.getKeyWork();
        })
        // 关闭弹窗
        const closeSearchBox = () => {
            context.emit('handleClose')
        }
        // 清除关键字
        const clearWords = async () => {
            state.searchVal = '';
            state.keyWorkList = await headerController.getKeyWork();
        }

        // 跳转搜索结果页
        const search = (key) =>{
            key = key.toString().replace(/<.*?>/ig, "");
            window.location.href="/ProductSearch?keyword=" + key+"&effectSearch=1";
        }

        // 手机图搜
        const searchProByCamPhone = async () => {
            let file: any = uploadImg.value,
                size: number = file.files[0].size,
                isImg: boolean = file.files[0].type.includes('image');
            if(size > 5 * 1024 * 1024) {
                alert('The size of the image should be less than 5M!');
                return false;
            } else if(!isImg) {
                alert('The image must be in one of the following formats: .jpg, .png, .jpeg, .bmp, .gif, .webp, .tiff, .ppm!');
                return false;
            }
            // 调用接口
            let result: any = await headerController.uploadSearchImg(file.files[0]);
            if(result.status){
                window.location.href = '/productImageSearch.do?key=' + result.message;
            } else {
                alert(result.message === '-1' ?
                    'The image length and width must be greater than or equal 200 pixels and smaller than or equal to 1024pixes.'
                    : 'The image must be in one of the following formats: .jpg, .png, .jpeg, .bmp, .gif, .webp, .tiff, .ppm!');
            }
        }

        const searchkeywordTip = async (val) => {
            if(state.searchVal) {
                // 请求接口获取到搜索结果列表数据
                state.keyWorkList =  await headerController.searchkeywordTip(val);
                let reg = new RegExp(state.searchVal, "g");
                state.keyWorkList = state.keyWorkList.map((value) => {
                    let list:any = [];
                    let hText: any = value.replace(reg, (match) => {
                        return `<b>${match}</b>`;
                    });
                    list.push(hText);
                    return list;
                });
            }
        }

        return{
            ...toRefs(state),
            clearWords,
            search,
            closeSearchBox,
            searchProByCamPhone,
            searchkeywordTip,
            uploadImg
        }
    }
})
