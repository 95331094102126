import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'

export default function () {

  const history = import.meta.env.SSR ? createMemoryHistory() : createWebHistory()

  return createRouter({
    history: history,
    routes: [
        // 帮助页
      {
        path: '/help',
        component: () => import('../views/help/Help.vue'),
            children: [
              {
                path: '/HelpCenter/HelpAsk',
                name: 'HelpAsk',
                component: () => import('@/views/help/helpRight/helpAsk/HelpAsk.vue')
              },
              {
                path: '/HelpCenter',
                name: 'Help Center',
                component: () => import('@/views/help/helpRight/helpList/HelpList.vue')
              },
              {
                path: '/HelpCenter/HelpCategory',
                name: 'HelpCategory',
                component: () => import('@/views/help/helpRight/helpList/HelpList.vue')
              },
              {
                path: '/HelpCenter/HelpDetail',
                name: 'HelpDetail',
                component: () => import('@/views/help/helpRight/helpDetail/HelpDetail.vue')
              },
              {
                path: '/HelpCenter/HelpSearch',
                name: 'HelpSearch',
                component: () => import('@/views/help/helpRight/helpSearch/HelpSearch.vue')
              }
            ]
      },
      {
        path: '/p-:productId(\\d+)-:pathMatch(.*).html',
        name: 'ProductDetail',
        component: () => import('@/views/productDetail/ProductDetail.vue')
      },
        // not found
      {
        path: '/PageNotFound',
        name: 'PageNotFound',
        component: () => import('@/views/NotFound.vue')
      },
      {
        path: '/Error',
        name: 'Error',
        component: () => import('@/views/Error.vue')
      },
      {
        path: '/',
        name: 'index',
        component: () => import('@/views/index/Index.vue')
      },
      {
        path:'/Product',
        name: 'product',
        component: () => import('@/views/product/productList/Product.vue')
      },
      {
        path:'/top-100-sales',
        name:'top-100-sales',
        component: () => import('@/views/product/weeklyHotList/WeeklyHotList.vue'),
        meta:{
          disableGlobalContStyle: true
        }
      },
      {
        path: '/mix-beads-wholesale/:page(\\d+).html',
        name: 'MixProductsList',
        component: () => import('@/views/product/productList/Product.vue'),
        props: route => ({ areaType: 'MixProducts', categoryId: '0', page: route.params.page })
      },
      {
        path: '/mix-beads-wholesale/:mode(\\d+)-:page(\\d+).html',
        name: 'MixProductsModeList',
        component: () => import('@/views/product/productList/Product.vue'),
        props: route => ({ areaType: 'MixProducts', mode: route.params.mode, page: route.params.page })
      },
      {
        path: '/mix-beads-wholesale/:categoryName/:categoryId(\\d+)-:mode(\\d+)-:page(\\d+).html',
        name: 'MixProductsCategoryModeList',
        component: () => import('@/views/product/productList/Product.vue'),
        props: route => ({ areaType: 'MixProducts', categoryId: route.params.categoryId, mode: route.params.mode, page: route.params.page })
      },
      {
        path: '/mix-beads-wholesale/:categoryName/:categoryId(\\d+)-:page(\\d+).html',
        name: 'MixProductsCategoryList',
        component: () => import('@/views/product/productList/Product.vue'),
        props: route => ({ areaType: 'MixProducts', categoryId: route.params.categoryId, page: route.params.page })
      },
      {
        path: '/mix-beads-wholesale/:categoryName/:categoryId(\\d+)-:page(\\d+)-:propertyValue(.*).html',
        name: 'MixProductsCategoryPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        props: route => ({ areaType: 'MixProducts', categoryId: route.params.categoryId, page: route.params.page, propertyValue: route.params.propertyValue })
      },
      {
        path: '/mix-beads-wholesale/:page(\\d+)-:propertyValue(.*).html',
        name: 'MixProductsPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        props: route => ({ areaType: 'MixProducts', categoryId: '0', page: route.params.page, propertyValue: route.params.propertyValue })
      },
        // 登录页
     /* {
        path: '/Customer/SignIn',
        name: 'Sign In / Register',
        component: () => import('@/views/login/LoginPage.vue')
      },
        // my account
      {
        path:'/',
        component: () => import('@/views/myaccount/Home.vue'),
        children: [
          {
            path: '/MyAccount',
            name: 'My Account',
            component: () => import('@/views/myaccount/home/Home.vue'),
            meta: {
              name: 'My Account',
              title:'My Account'
            }
          },
          {
            path: '/MyAccount/Newsletter',
            name: 'newsletter',
            component: () => import('@/views/myaccount/newsletter/Newsletter.vue'),
            meta: {
              name: 'Newsletter',
              title:'newsletter'
            }
          },
          {
            path: '/MyAccount/AccountSetting',
            name: 'accountSetting',
            component: () => import('@/views/myaccount/accountSetting/AccountSetting.vue'),
            meta: {
              name: 'Account Setting',
              title:'accountSetting'
            }
          },
          {
            path: '/TicketCenter/UnreadedTickets',
            name: 'Unread Tickets',
            component: () => import('@/views/myaccount/ticket/ticketList/TicketList.vue'),
            meta: {
              name: 'Unread Tickets',
              title:'Unread Tickets'
            }
          },
          {
            path: '/TicketCenter/AllTickets',
            name: 'allTickets',
            component: () => import('@/views/myaccount/ticket/ticketList/TicketList.vue'),
            meta: {
              name: 'All Tickets',
              title:'AllTickets'
            }
          },
          {
            path: '/MyAccount/myFootPrint',
            name: 'myFootPrint',
            component: () => import('@/views/myaccount/personalization/myFootprint/MyFootprint.vue'),
            meta: {
              name: 'My FootPrints',
              title:'myFootPrint'
            }
          },
          {
            path: '/TicketCenter/TicketDetails',
            name: 'ticketDetails',
            component: () => import('@/views/myaccount/ticket/ticketDetail/TicketDetail.vue'),
            meta: {
              name: 'Ticket Details',
              title:'TicketDetails'
            }
          },
          {
            path: '/TicketCenter/Complaint',
            name: 'complaint',
            component: () => import('@/views/myaccount/ticket/ticketEdit/TicketEdit.vue'),
            meta: {
              name: 'Complaint',
              title:'Complaint'
            }
          },
          {
            path: '/TicketCenter/SubmitATicket',
            name: 'Submit a ticket',
            component: () => import('@/views/myaccount/ticket/ticketEdit/TicketEdit.vue'),
            meta: {
              name: 'Submit a ticket',
              title:'Submit a ticket'
            }
          },
          {
            path: '/Coupon/Coupon',
            name: 'coupon',
            component: () => import('@/views/myaccount/coupon/Coupon.vue'),
            meta: {
              name: 'Coupon',
              title:'coupon'
            }
          },
          {
            path: '/Points',
            name: 'points',
            component: () => import('@/views/myaccount/points/Points.vue'),
            meta: {
              name: 'Points',
              title:'points'
            }
          },
          {
            path: '/MyAccount/MyReviews',
            name: 'MyReviews',
            component: () => import('@/views/myaccount/personalization/myReviews/MyReview.vue'),
            meta: {
              name: 'MyReviews',
              title:'MyReviews'
            }
          },
          {
            path: '/MyAccount/AddressBook',
            name: 'Address Book',
            component: () => import('@/views/myaccount/personalization/addressBook/addressBookList/AddressBook.vue'),
            meta: {
              name: 'Address Book',
              title:'Address Book'
            }
          },
          {
            path: '/MyAccount/AddressBookDetails',
            name: 'AddressBookDetails',
            component: () => import('@/views/myaccount/personalization/addressBook/addressBookDetail/AddressBookDetail.vue'),
            meta: {
              name: 'Address Book Details',
              title:'Address Book Detail'
            }
          },
          {
            path: '/WishList/MyWishList',
            name: 'MyWishList',
            component: () => import('@/views/myaccount/personalization/myWishList/myWishList/MyWishList.vue'),
            meta: {
              name: 'MyWishList',
              title:'MyWishList'
            }
          },
          {
            path: '/WishList/:id.html',
            name: 'MyWishListDetail',
            component: () => import('@/views/myaccount/personalization/myWishList/myWishListDetail/MyWishListDetail.vue'),
            meta: {
              name: 'MyWishList',
              title:'MyWishListDetail'
            }
          },
          {
            path: '/MyAccount/PaymentHistory',
            name: 'paymentHistory',
            component: () => import('@/views/myaccount/paymentHistory/PaymentHistory.vue'),
            meta: {
              name: 'Payment History',
              title:'payment History'
            }
          },
          {
            path: '/Special-Bargains-for-You',
            name: 'Special-Bargains-for-You',
            component: () => import('@/views/myaccount/personalization/specialBargainsForYou/SpecialBargainsForYou.vue'),
            meta: {
              name: 'Special Bargains for You',
              title:'Special-Bargains-for-You'
            }
          },
          {
            path: '/MyAccount/OnlyForYouProducts',
            name: 'Only For You',
            component: () => import('@/views/myaccount/personalization/onlyForYou/OnlyForYou.vue'),
            meta: {
              name: 'Only For You',
              title:'Only For You'
            }
          },
          {
            path: '/MyAccount/CanceledProducts',
            name: 'Canceled Products',
            component: () => import('@/views/myaccount/purchaseHistory/cancelledItems/CancelledItems.vue'),
            meta: {
              name: 'Canceled Products',
              title:'Canceled Products'
            }
          },
          {
            path: '/MyAccount/PurchaseHistory',
            name: 'PurchaseHistory',
            component: () => import('@/views/myaccount/purchaseHistory/purchaseHistory/PurchaseHistory.vue'),
            meta: {
              name: 'Purchase History',
              title:'Purchase History'
            }
          },
          {
            path: '/Cash/CashAccount',
            name: 'Cash Account',
            component: () => import('@/views/myaccount/cashAccount/CashAccount.vue'),
            meta: {
              name: 'Cash Account',
              title:'Cash Account'
            }
          },
        ]
      }*/
      {
        path: '/.+',
        name: 'PageNotFound',
        component: () => import('@/views/NotFound.vue')
      },
    ],
  })
}