import {useContext} from "@/Ph";
import Cookies from 'js-cookie'
export const getCookie=function (cookieKey){
    const context = useContext();
    if(context.isClient){
        return Cookies.get(cookieKey)
    }else{
        return context.request.cookies[cookieKey]
    }
}
export const setCookie=function (cookieKey,value){
    const context = useContext();
    if(context.isClient){
        return Cookies.set(cookieKey,value)
    }else{
        return context.response.cookie(cookieKey,value)
    }
}