import { defineStore } from 'pinia'
import HeaderDto from "@/service/model/header/HeaderDto";

export default defineStore('user', {
  state: () => ({
    systemError: false,
    pageNotFound: false,
    thirdlyCrumb: null,
    headerDto: <HeaderDto>{}
  }),
  actions: {
    updateHeader(newValue: HeaderDto) {
      this.headerDto = newValue;
    },
    updateHeadCart({qty, price}) {
      this.headerDto.headerCustomerDto.cartItemCount = qty
      this.headerDto.headerCustomerDto.cartTotalAmount = price
    },
    updateHeadWishList(qty) {
      this.headerDto.headerCustomerDto.wishListItemCount = qty
    },
    updateHeadName({firstName, lastName}) {
      this.headerDto.firstName = firstName
      this.headerDto.lastName = lastName
    },
  }
})
