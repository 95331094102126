import { onMounted, ref } from 'vue';
export default {
    name: "Confirm",
    props: {
        title: {
            type: String,
            default: 'Confirm'
        },
        text: {
            type: String,
            default: ""
        },
        confirmText:{
            type: String,
            default: 'Confirm'
        },
        cancelText: {
          type: String,
          default: 'Cancel'
        },
        submitCallback: {
            type: Function // 确认事件
        },
        cancelCallback: {
            type: Function // 取消事件
        }
    },
    setup(props) {
        const fade = ref(false)
        onMounted(() => {
            // 当元素渲染完毕立即过渡的动画不会触发
            setTimeout(() => {
                fade.value = true
            }, 0)
        })
        return { fade }
    }
}