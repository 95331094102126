import {inject, nextTick, onMounted, reactive, ref, toRefs} from 'vue';
import AddToCartDto from "@/service/model/cart/AddToCartDto";
import {cartController} from "@/service/CartController";
import popLogin from "@/components/common/popLogin";
import useUserStore from "@/store/user";
import {itegtm} from "@/utils/gtm";
import HeaderDto from "../../../service/model/header/HeaderDto";
export default {
    name: "AddToCart",
    props: {
        pid: Number,
        qty: Number,
        dom: {},
        div: {},
        submitCallback: {
            type: Function // 确认事件
        },
        cancelCallback: {
            type: Function // 取消事件
        }
    },
    setup(props, content) {
        const store = useUserStore();
        const fade = ref(false)
        const data = reactive({
            tipMsg: <string> '',
            item:"1 "+"item".translate(),
            unit:"1 "+"unit".translate()
        })

        const addToCart = async () => {
            props.dom.classList.add('addCartLoading')
            const returnObj:AddToCartDto = await cartController.addToCart(props.pid, props.qty)
            props.dom.classList.remove('addCartLoading')
            // 判断是否需要登录
            if(returnObj.needLogin) {
                popLogin().then(addToCart, ()=>{}).catch(props.cancelCallback);
                return;
            } else {

                if(returnObj.status){
                    // 修改cart头部信息
                   store.updateHeadCart({
                        qty: returnObj.qty,
                        price: returnObj.totalPrice
                    });
                    try{
                        let headInfo = store.headerDto;
                        if(!headInfo.simulatedLogin){
                            let ga4ListName = "Other";
                            let ga4ListId = "O001";
                            let detailPattern = /p-(\d+)-(.*).html/ig;
                            let herf = window.location.href;
                            if(detailPattern.test(herf)){
                                ga4ListName = "Product";
                            }
                            itegtm.addToCart([props.pid],ga4ListName,ga4ListId,[props.qty]);
                        }
                    }catch(e){
                        console.log("itegtm is not defined");
                    }
                }

                if(returnObj.qty>1){
                    data.item=returnObj.qty+" "+"items".translate();
                }
                if(returnObj.cartQty>1){
                    data.unit=returnObj.cartQty+" "+"units".translate();
                }
                switch (returnObj.message) {
                    case 'SHOW_ADD_SUCCESS_TIP': data.tipMsg = 'addToCart.successTip'.translate(data.item,data.unit,returnObj.totalPrice.money());
                        break;
                    case 'SHOW_DELAY_TIP': data.tipMsg = 'addToCart.delayTip'.translate(data.item,data.unit,returnObj.totalPrice.money());
                        break;
                    case 'SHOW_LARGE_PACKAGE_TIP': data.tipMsg = 'addToCart.largePackageTip'.translate(data.item,data.unit,returnObj.totalPrice.money());
                        break;
                    case 'ERROR_SHOPPINGCART_PRODUCT_NOT_EXISTS': data.tipMsg = 'addToCart.produceNotExistsError'.translate();
                        break;
                    case 'ERROR_SHOPPINGCART_PRODUCT_OUT_OF_STOCK': data.tipMsg = 'addToCart.productOutOfStockError'.translate(returnObj.allQty,returnObj.haveQty);
                        break;
                    case 'ERROR_SHOPPINGCART_PRODUCT_NO_PRICE': data.tipMsg = 'addToCart.productNoPriceError'.translate();
                        break;
                    default:
                        data.tipMsg = returnObj.message
                }

                props.submitCallback(returnObj);

                // 设置位置
                setPosition(props.dom, props.div)
            }

            // 当元素渲染完毕立即过渡的动画不会触发
            setTimeout(() => {
                fade.value = true
            }, 0)
        };

        onMounted(addToCart);

        // add to cart 弹窗位置
        const setPosition = async (clickWrap, popWrap) => {
            await nextTick()
            let {width, height, top, left} = clickWrap.getBoundingClientRect()
            popWrap.style.position = "absolute"
            popWrap.style.zIndex = 99
            popWrap.style.top = top  + window.scrollY - popWrap.clientHeight - 6 + "px"
            popWrap.style.left = left + window.scrollX - ((popWrap.clientWidth- clickWrap.clientWidth)/2) + "px"
        }


        return {
            fade,
            ...toRefs(data)
        }
    }
}