import {createApp, createVNode, nextTick, render} from "vue"
import AddToWishList from '@/components/common/addToWishList/AddToWishList.vue'

export function addToWishList(pids:number[], dom,excludeDirId:number=0, store):Promise<any>{
    // 创建一个dom节点
    const div = document.createElement('div')
    div.setAttribute('class', 'popWishList-container')
    // 挂载到body就可以在最顶层直接控制显示隐藏
    document.body.appendChild(div)

    return new Promise((resolve: any, reject)=>{
        // 封装组件属性方法
        const submitCallback = () => {
            //调用完毕后应该清空节点
            div.remove()
            resolve()
        }

        // 封装组件属性方法
        const cancelCallback = () => {
            //清空节点
            div.remove()
            reject()
        }

        // 在此处才创建节点并挂载属性
        const wishApp = createApp(AddToWishList, {
            submitCallback,
            cancelCallback,
            pids,
            excludeDirId,
            dom,
            div
        })

        wishApp.use(store).mount(div)

    });
}