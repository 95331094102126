import useUserStore from "@/store/user"
export function  errorHandler(err:any, vm, info) {
    const userStore = useUserStore();
    userStore.systemError = true;
    console.error("errorHandler")
    console.error(err.stack)
    if (typeof window !== 'undefined') {
        const log = {
            message: err.stack,
            info: info,
            url: window.location.href
        };
        sendLogToServer(log);
    }
}

export function sendLogToServer(log) {
        fetch('/client/logs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(log),
        }).catch(err => {
            console.error('Failed to send log to server:', err);
        });
}