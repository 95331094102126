import {PostMapping, RequestMapping2Class, RequestParam} from "@/http";
import AddToCartDto from "@/service/model/cart/AddToCartDto";
import BatchAddToCartDto from "@/service/model/cart/BatchAddToCartDto";


@RequestMapping2Class("/cart")
class CartController {
    @PostMapping("/addToCart")
    addToCart(@RequestParam("productId") productId: number, @RequestParam("qty") qty: number):Promise<AddToCartDto>{
        throw 1;
    }
    @PostMapping("/batchAddToCart")
    batchAddToCart(@RequestParam("productIds") productIds: number[]):Promise<BatchAddToCartDto>{
        throw 1;
    }
    @PostMapping("/buyNow")
    buyNow(@RequestParam("productId") productId: number, @RequestParam("qty") qty: number):Promise<AddToCartDto>{
        throw 1;
    }
}

export const cartController = new CartController;