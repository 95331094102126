import App from './layouts/Index.vue'
import createRouter from "./router"
import { createHead } from "unhead"
import { createSSRApp } from 'vue'

import {messageInit} from "@/lang/message";
// @ts-ignore
import {errorHandler,sendLogToServer} from "@/ErrorProcess";
import ClientOnly from "@/components/common/clientOnly/ClientOnly.vue";

export default function() {
  // 加载翻译
  messageInit('en');

  const app = createSSRApp(App)

  if (import.meta.env.SSR) {
    // ... 仅在服务端执行的逻辑
  }

  if (typeof window !== 'undefined') {
      // 捕获未处理的Promise拒绝
      window.addEventListener('unhandledrejection', event => {
          // 将未处理的Promise拒绝信息发送到服务器
          sendLogToServer({
              message: event.reason.message,
              stack: event.reason.stack,
              url: window.location.href
          });
      });
      //捕获全局错误
      window.onerror = function (message, source, lineno, colno, error) {
          // 将错误信息发送到服务器
          sendLogToServer({
              message: message,
              source: source,
              lineno: lineno,
              colno: colno,
              stack: error ? error.stack : null,
              url: window.location.href
          });
      }
  }

  const router = createRouter()
  const head:any = createHead()
  app.use(head)
  app.use(router);
  app.component('client-only', ClientOnly);
  app.config.errorHandler = errorHandler;
  return { app, router, head }
}