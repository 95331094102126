export default class ModalData {
    modalShow: boolean = false;
    title: string = '';
    showCancelButton: boolean =false;
    showConfirmButton: boolean =false;

    constructor(modalShow: boolean, title: string, showCancelButton: boolean, showConfirmButton: boolean) {
        this.modalShow = modalShow;
        this.title = title;
        this.showCancelButton = showCancelButton;
        this.showConfirmButton = showConfirmButton;
    }
}