import {reactive, toRefs, onMounted, defineComponent} from 'vue';
import {loginController} from '@/service/LoginController'

import useValid from "@/utils/valid";
export default defineComponent({
    props:{
        popCallBack: {
            type: Function
        }
    },
    setup(props, content) {
        const loginForm = reactive({
            email: <string> '',
            password: <string> '',
            validateCode: undefined,
            remember: false
        });
        const state = reactive({
            dateTime: <Date> new Date(),
            showSignInVerify: <boolean> false,
            validateImg: <string> '/bff/validateCode.jpeg'
        })
        const {errors, validField,valid,validRules} = useValid(loginForm);
        validRules.password=validRules.loginPassword;
        onMounted(async () => {
            // 是否展示验证码
            let params:any = getUrlParams('access');
            // 3s后自动消失
             setTimeout(async ()=>{
                let showCode: any = await loginController.initSignIn(params);
                 state.showSignInVerify = showCode.isLoginValidateCodeDisplay;
                 if(showCode.isLoginSuccess){
                     location.href='/MyAccount';
                 }
            }, 3000)
        })

        const changeValidateCode = () =>{
            state.validateImg = '/bff/validateCode.jpeg?r=' + Math.random();
        }
        // 登录
        const login = async () => {
            if(!valid()){
                return false;
            }
            try {
                // 调用登录接口
                await loginController.login(loginForm);
                let returnUrl: any = getUrlParams('returnUrl');
                // 登录成功跳转
                if(returnUrl == null) {
                    // 是否弹窗登录
                    if(props.popCallBack) {
                        // @ts-ignore
                        props.popCallBack();
                    } else {
                        if(document.referrer) {
                            window.location.href = document.referrer;
                        } else {
                            window.location.href = '/'
                        }
                    }

                } else {
                    window.location.href = returnUrl
                }

            }catch (e){
                let errData = e.response.data;
                loginForm.password="";
                // 是否展示验证码
                let showCode: any = await loginController.initSignIn('');
                state.showSignInVerify = showCode.isLoginValidateCodeDisplay;

                // 刷新验证码
                if(state.showSignInVerify) {
                    changeValidateCode();
                }
                if(errData.err_code=="510") {
                    if (errData.message == "ERROR_CUSTOMER_EMAIL_NOT_EXISTS") {
                        errors["email"]='login.email.notexists'.translate();
                    } else if (errData.message == 'ERROR_CUSTOMER_LOCKED') {
                        errors["email"]='login.email.locked'.translate();
                    }else if (errData.message == 'ERROR_CUSTOMER_PASSWORD_ERROR') {
                        errors["password"]='register.password.error'.translate();
                    }else if (errData.message == "ERROR_VERIFYNUMBER") {
                        errors["validateCode"]='login.validatecode.error'.translate();
                    }
                }else{
                    throw e;
                }
            }
        }

        // 获取地址栏参数
        const getUrlParams = (name) => {
            let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)")
            let param = window.location.search.substr(1).match(reg);
            if(param!=null)return  unescape(param[2]);
            return null;
        }

        const toReg=()=>{
            content.emit('toReg')
        }

        return {
            ...toRefs(state),
            ...toRefs(loginForm),
            changeValidateCode,
            toReg,
            login,
            getUrlParams,
            errors, validField
        }
    }

})

