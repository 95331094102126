import {nextTick, onMounted, reactive, ref, toRefs} from 'vue';
import BatchAddToCartDto from "@/service/model/cart/BatchAddToCartDto";
import {cartController} from "@/service/CartController";
import popLogin from "@/components/common/popLogin";
import useUserStore from "@/store/user";
export default {
    name: "BatchAddToCart",
    props: {
        pids: Array,
        dom: String,
        div: String,
        submitCallback: {
            type: Function // 确认事件
        },
        cancelCallback: {
            type: Function // 取消事件
        }
    },
    setup(props, content) {
        const store = useUserStore()
        const fade = ref(false)
        const data = reactive({
            tipMsg: <string> '',
            item:"1 "+"item".translate(),
            unit:"1 "+"unit".translate()
        })

        const batchAddToCart = async () => {
            const returnObj:BatchAddToCartDto = await cartController.batchAddToCart(props.pids)
            // 判断是否需要登录
            if(returnObj.needLogin) {
                popLogin().then(batchAddToCart, ()=>{}).catch(props.cancelCallback);
                return;
            } else {
                if(returnObj.qty && returnObj.totalPrice){
                    // 修改cart头部信息
                    store.updateHeadCart( {
                        qty: returnObj.qty,
                        price: returnObj.totalPrice
                    });
                }

                if(returnObj.qty>1){
                    data.item=returnObj.qty+" "+"items".translate();
                }
                if(returnObj.cartQty>1){
                    data.unit=returnObj.cartQty+" "+"units".translate();
                }
                switch (returnObj.message) {
                    case 'SHOW_ADD_SUCCESS_TIP': data.tipMsg = 'addToCart.successTip'.translate(data.item,data.unit,returnObj.totalPrice.money());
                        break;
                    case 'ERROR_SHOPPINGCART_PRODUCT_NOT_EXISTS': data.tipMsg = 'addToCart.produceNotExistsError'.translate();
                        break;
                    case 'ERROR_SHOPPINGCART_PRODUCT_OUT_OF_STOCK': data.tipMsg = 'batchAddToCart.outOfStockTip'.translate();
                        break;
                    case 'ERROR_SHOPPINGCART_PRODUCT_NO_PRICE': data.tipMsg = 'batchAddToCart.productNoPriceError'.translate();
                        break;
                    default:
                        data.tipMsg = returnObj.message
                }
                // 设置位置
                setPosition(props.dom, props.div)

                props.submitCallback(returnObj);
            }

            // 当元素渲染完毕立即过渡的动画不会触发
            setTimeout(() => {
                fade.value = true
            }, 0)
        };

        onMounted(batchAddToCart);
        
        // add to cart 弹窗位置
        const setPosition = async (clickWrap, popWrap) => {
            await nextTick()
            let {width, height, top, left} = clickWrap.getBoundingClientRect()
            popWrap.style.position = "absolute"
            popWrap.style.top = top  + window.scrollY - popWrap.clientHeight - 6 + "px"
            popWrap.style.left = left + window.scrollX - ((popWrap.clientWidth- clickWrap.clientWidth)/2) + "px"
        }

        return {
            fade,
            ...toRefs(data)
        }
    }
}