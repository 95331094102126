import {reactive, toRefs, onMounted, defineComponent, inject} from 'vue';
import PhoSeaPhoto from "../search/Search.vue";
import LeftMenu from "../leftMenu/LeftMenu.vue";
import HeaderDto from "@/service/model/header/HeaderDto";
import HeaderCustomerDto from "@/service/model/header/HeaderCustomerDto";
import {headerController} from "@/service/HeaderController";
import {useContext} from "@/Ph";

export default defineComponent({
    methods: {useContext},
    components: {PhoSeaPhoto, LeftMenu },
    setup() {
        const state = reactive({
            // 是否搜索弹窗
            isShowPhoSearch: <boolean> false,
            // 是否显示左侧菜单
            isShowLeftMenu: <boolean> false,
            headInfo: <HeaderDto> {},
            headCustomerInfo:<HeaderCustomerDto> {},
            isShowGoBack: <boolean> false
        })
        let headInfo = <HeaderDto>inject('headInfo');
        onMounted(async ()=>{

            state.headInfo =  headInfo;
            state.headCustomerInfo = state.headInfo.headerCustomerDto;
            // 判断是否显示 返回按钮
            if(document.referrer){
                state.isShowGoBack = true;
            }
        })
        // 点击搜索按钮显示弹窗
        const handleSearch = () => {
            state.isShowPhoSearch = true
        }
        // 关闭弹窗
        const handleClose = () => {
            state.isShowPhoSearch = false
        }
        // 显示左侧菜单
        const handleShowLeftMenu = () => {
            state.isShowLeftMenu =  true;
            document.body.style.height =  document.documentElement.scrollHeight+'px';
            document.body.style.overflow = "hidden";
        }
        // 隐藏左侧菜单
        const handleCloseMenu = () => {
            state.isShowLeftMenu =  false
        }
        // 返回上一页
        const goBack = () => {
            history.go(-1);
        }
        return{
            ...toRefs(state),
            handleSearch,
            handleClose,
            handleShowLeftMenu,
            handleCloseMenu,
            goBack
        }
    }
})