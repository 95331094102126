<script lang="ts">
import {
  reactive,
  toRefs,
  onMounted,
  defineComponent
} from 'vue';
import Top from "./pcHeader/top/Top.vue";
import Search from "./pcHeader/search/Search.vue";

import Nav from "./pcHeader/navigation/Nav.vue";
import PhoneTop from "./phoneHeader/top/PhoneTop.vue";
import PhoneNav from "./phoneHeader/navigation/Nav.vue";

export default defineComponent({
  props: {
    pageLocation: Number,
    locationType: Number
  },
  components:{
    PhoneTop,
    PhoneNav,
    Nav,
    Search,
    Top
  },
  setup(props) {
    const state = reactive({
      isFix: <boolean> false
    })

    onMounted(() => {
      window.addEventListener("scroll",showFixHeader,true);
    })

    // 是否固定头部
    const showFixHeader = () =>{
      //  bug
      // let isNotCart = props.locationType != LocationType.OTHER ? false : true;
      let isNotCart = true;
      if(isNotCart) {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if(scrollTop > 280) {
          state.isFix = true
        } else {
          state.isFix = false
        }
      } else {
        state.isFix = false
      }
    }

    return{
      ...toRefs(state),
      showFixHeader
    }
  }
})
</script>
<template>
  <Top :page-location="pageLocation"/>
  <div :class="{'fixHeader': isFix }">
    <Search :location-type="locationType"/>
    <Nav :page-location="pageLocation" :location-type="locationType" :is-fix="isFix"/>
  </div>
  <div class="fixHeader hidden-sm hidden-md">
    <PhoneTop />
    <PhoneNav />
  </div>
</template>
<style lang="less">
  .fixHeader{position: fixed;width: 100%;background: #fff;top: 0;left: 0;border-bottom: none;box-shadow: -1px -1px 5px rgb(51 51 51 / 80%);z-index: 20;}
</style>


